import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import { Card } from "./DashedBorderSixFeatures";
import { Title } from "components/cards/ThreeColSlider";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;


const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      title: "Full Residential Remodeling",
      description: "Transform your living space with our comprehensive remodeling services, tailored to meet your unique needs. From seamless design integration to enhancing energy efficiency with new windows and doors, we ensure quality in every detail."
    },
    {
      title: "Exterior Upgrades: Siding, Roofing, and More",
      description: "Elevate your home’s exterior with our expert installation services for siding, roofing, windows, and doors. Improve curb appeal and functionality with a wide selection of materials and styles."
    },
    {
      title: "Kitchen and Bathroom Upgrades",
      description: "Turn your kitchen and bathroom into luxurious and functional spaces with custom countertops, cabinets, and high-end finishes. Our focus on quality materials and craftsmanship ensures your dream spaces become a reality."
    },
    {
      title: "Custom Home Building and Outdoor Living",
      description: "From custom home designs to creating inviting outdoor living spaces with decks and porches, our team brings your vision to life with precision and style, ensuring every detail fits your lifestyle."
    },
    {
      title: "Structural and Functional Enhancements",
      description: "Strengthen and beautify your home with our superior services, house framing, and interior finish work, alongside essential additions."
    },
    {
      title: "Finishing Touches: Finish Carpentry, Flooring, and Insulation",
      description: "Complete your project with our comprehensive finishing services. From flooring to finish carpentry and ensuring your home is well-insulated, our meticulous attention to detail guarantees a perfect finish."
    }
  ];
  

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <Title>{card.title}</Title>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
