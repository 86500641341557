import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
export const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "https://i.imgur.com/yLFickQ.jpg",
      title: "Modern Kitchen and Dining Transformation",
      description: "Experience culinary bliss in this beautifully renovated kitchen and dining space, featuring sleek white cabinetry, sophisticated black countertops, and state-of-the-art appliances. The inviting dining area, with its elegant table and plush chairs, promises comfort for both daily meals and festive gatherings. Natural light and modern pendant lighting accentuate the warm hardwood floors, creating an ambiance of contemporary elegance.",
    },
    
    {
      imageSrc: "https://i.imgur.com/8Y521j3.jpg",
      title: "Modern Deck Perimeter",
      description: "The modern deck perimeter is thoughtfully crafted with privacy in mind, featuring dark metal balusters and white posts that provide a sleek contrast. The deck is ideal for those who appreciate contemporary design and a secluded outdoor environment.",
    },
    {
      imageSrc: "https://i.imgur.com/MCWFXDB.jpg",
      title: "Elegant Outdoor Living Deck",
      description: "Elevate your outdoor entertainment with this custom-designed deck, perfect for both relaxation and social events. The robust decking material ensures longevity, while the classic white railing presents a clean, polished look. Step down to a spacious patio area, promising endless summer barbecues and serene moments in the comfort of your backyard. This home extension blurs the lines between indoor luxury and outdoor tranquility.",
    },
    {
      imageSrc: 'https://i.imgur.com/GMjeVb8.jpg',
      title: "Serene Bedroom with Natural Light",
      description: "Capturing the essence of serenity, this bedroom design features a comfortable bed set against a backdrop of soft, neutral tones, complemented by abundant natural light. It's a tranquil retreat designed for rest and rejuvenation."
    },
    {
      imageSrc: "https://i.imgur.com/bHN4MJq.jpg",
      title: "Sophisticated Deck Design",
      description: "This sophisticated deck design features a chic grey composite decking material, bordered by a clean-lined white railing that offers unobstructed views of the surrounding trees and lawn. It's a perfect blend of modern design with natural beauty, providing a tranquil setting for relaxation."
    }, {
      imageSrc: "https://i.imgur.com/3tR4Qwl.jpg",
      title: "Chic Bathroom with Modern Amenities",
      description: "The bathroom exudes chic elegance with its modern amenities, featuring a sleek vanity, marble countertops, and a full-sized mirror. The soft color tones and contemporary fixtures create a spa-like atmosphere that's both refreshing and sophisticated.",
    },
    {
      imageSrc: "https://i.imgur.com/VQh89xj.jpg",
      title: "Innovative Underdeck Storage",
      description: "This practical underdeck storage solution maximizes outdoor space with smart design. The white paneled doors blend seamlessly with the deck's aesthetic, providing easy access to gardening tools and outdoor equipment while maintaining a clean and uncluttered appearance.",
    }
  ]

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Some of Our Work</Heading>

          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon /></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon /></NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                </TitleReviewContainer>
                <Description>{card.description}</Description>
              </TextInfo>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
