import React from 'react';
import tw from 'twin.macro';

// Define the styled paragraph with twin.macro
const StyledCopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

const Copyright = () => {
    const currentYear = new Date().getFullYear(); // Gets the current year

    return (
        <div>
            {/* Render the styled paragraph with dynamic year and custom message */}
            <StyledCopyrightText>
                &copy; {currentYear} Ferragamo Custom Builders. All Rights Reserved.
            </StyledCopyrightText>
        </div>
    );
};

export default Copyright;