import React from "react";

import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/FullWidthWithImage.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FAB from "components/cta/FAB";

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
export const HighlightedText = tw.span`text-primary-500`;

export default () => (
  <AnimationRevealPage>
    <FAB />

      <Hero />
    <MainFeature />
    <span id="services" />
    <Features
      heading={
        <>
          <HighlightedText>We Provide</HighlightedText>&nbsp;Quality Services.
        </>
      }
    />

    <SliderCard />
    <Testimonial textOnLeft={true} />
    <Footer />
  </AnimationRevealPage>
);
