import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { PrimaryLink } from './GetStarted';

function FAB() {
  const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' });

  return (
    <div
      style={{
        position: 'fixed',
        bottom: isBigScreen ? '50px' : '20px',
        right: isBigScreen ? '50px' : '50%',
        transform: isBigScreen ? 'none' : 'translateX(50%)',
        zIndex: 1000, // Ensure it's high enough to be on top of other elements
      }}
    >
      <PrimaryLink href='tel:+17817999135'>
        Call Today
      </PrimaryLink>
    </div>
  );
}

export default FAB;
