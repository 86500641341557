import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoText>Ferragamo Custom Builders LLC</LogoText>

          <CompanyDescription>
            At Ferragamo Custom Builders LLC, we transform your living spaces into the dream home you've always wanted. Based in Massachusetts' North Shore, our team specializes in premium home remodeling services. From residential renovations to custom design services, we're dedicated to excellence in every project. Why Choose Us? Expertise in Both Residential and Commercial Projects: Whether it's your home or your business, our skilled team is equipped to handle your remodeling needs with precision and care. Quality Materials for Quality Results: We believe in using only the highest quality materials to ensure your renovation not only looks fantastic but lasts for years to come.
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#about">About</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#services">Services</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="tel:+17817999135">Call Us Today</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Services</ColumnHeading>
          <LinkList>
            {['Custom home builder', 'Remodeler',
              'Deck builder',
              'Kitchen remodeler',
              'Siding contractor',
              'Bathroom remodeler',
              'General contractor',
              'Roofing contractor',
              'Construction company'].map((item, index) => (
                <LinkListItem id={item + index}>
                  {item}
                </LinkListItem>
              ))}
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Areas Served</ColumnHeading>
          <LinkList>
            {['Malden, MA, USA',
              'Andover, MA, USA',
              'Belmont, MA, USA',
              'Beverly, MA, USA',
              'Ipswich, MA, USA',
              'Melrose, MA, USA',
              'Peabody, MA, USA',
              'Reading, MA, USA',
              'Stoneham, MA, USA',
              'Lynnfield, MA, USA',
              'Tewksbury, MA, USA',
              'Swampscott, MA, USA',
              'Wilmington, MA, USA',
              'Saugus, MA 01906, USA',
              'Danvers, MA 01923, USA',
              'Middleton, MA 01949, USA',
              'Wakefield, MA 01880, USA',
              'Winchester, MA 01890, USA',
              'North Andover, MA 01845, USA',
              'North Reading, MA 01864, USA'].map((item, index) => (
                <LinkListItem id={item + index}>
                  {item}
                </LinkListItem>
              ))}
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
