import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import loveIllustrationImageSrc from "images/love-illustration.svg";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import { PrimaryLink } from "components/misc/Links.js";

export const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:pr-12 lg:pr-16 md:order-first` : tw`md:pl-12 lg:pl-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-6 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(StarIconBase)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;
const TestimonialHeading = tw.div`mt-4 text-xl font-bold`;
const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-700`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-16 h-16 sm:w-20 sm:h-20`;
const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

export default ({
  imageSrc = loveIllustrationImageSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  subheading = "Testimonials",
  heading = "Our Clients Love Us.",
  description = "From the initial concept to the final touches, our dedication to excellence shines through in every project.",
  textOnLeft = false,
  testimonials = [
    {
      stars: 5,
      heading: "Extremely Pleased with the Work",
      quote:
        "We were extremely pleased with the work we had done. They were highly skilled, professional, neat and courteous. Went above and beyond to finish the job as promised. They made sure to clean up the work area before leaving. We would recommend Chris without hesitation.",
      customerName: "Barbara W.",
      customerTitle: "Reading, MA - Saw or Remove Concrete or Masonry",
      date: "10/28/2023"
    },
    {
      stars: 5,
      heading: "Knowledgeable and Accommodating",
      quote:
        "My husband and I were in need of a contractor to remove a load bearing wall and install a support beam to widen an entrance in our home. We contacted numerous contractors and Chris Ferragamo was one of the few to get back to us. Chris was able to come the next day to evaluate the project. We told him what we envisioned and our budget for the project. Not only was he the most knowledgeable contractor that came to evaluate the job, he also had the best pricing. When we agreed to move forward he was very accommodating to our schedules. He was able to order the materials and pull the permit that day. His workers came the following week at the time he said they would be there. They were quick, efficient, and polite. Chris followed up that same day to ensure the project went smoothly which it did. We will be using them for future projects.",
      customerName: "Analise A.",
      customerTitle: "Lynnfield, MA - Analyze a Structural Issue (Structural Engineer)",
      date: "1/25/2023"
    },
    {
      stars: 5,
      heading: "Outstanding Service",
      quote:
        "The best service. I recommended them for everyone.",
      customerName: "Slavica S.",
      customerTitle: "Malden, MA - Remodel a Kitchen",
      date: "3/19/2020"
    },
    {
      stars: 5,
      heading: "Impeccable Taste",
      quote:
        "Taste is impeccable on interior and exterior work.",
      customerName: "Robert S.",
      customerTitle: "Remodel a Bathroom",
      date: "11/8/2016"
    },
    {
      stars: 5,
      heading: "Thrilled to Recommend",
      quote:
        "Thrilled to recommend them and have them back for more work.",
      customerName: "Jenny C.",
      customerTitle: "Remodel a Kitchen",
      date: "11/8/2016"
    },
    {
      stars: 5,
      heading: "Responsive and Efficient",
      quote:
        "Very responsive and listened to my needs and provided alternate solutions that saved me money.",
      customerName: "Burlington, MA",
      customerTitle: "Repair, Replace or Seal an Existing Skylight",
      date: "10/26/2016"
    },
    {
      stars: 5,
      heading: "Phenomenal Quality",
      quote:
        "Phenomenal quality! Ferragamo Builders has so much expertise; time and time again for this reason I hire them back. They have ample times communicated with me, listened, so as to save me $$ in this tight economy. They are well rounded, prompt and punctual, professional and concerned with the consumer being satisfied ~ always doing follow-up calls with me to make sure all is as it should be.",
      customerName: "Andrea ?.",
      customerTitle: "Remodel or Renovate One or More Rooms",
      date: "10/26/2016"
    },
    {
      stars: 4,
      heading: "Above and Beyond",
      quote:
        "Went above and beyond solving my skylight (not closing,) for a super reasonable price. Neat, skilled, and pleasant to work with.",
      customerName: "Suzanne L.",
      customerTitle: "Somerville, MA - Repair, Replace or Seal an Existing Skylight",
      date: "10/26/2016"
    },
    {
      stars: 5,
      heading: "Prompt and Efficient",
      quote:
        "Prompt response, very efficient and good work.",
      customerName: "Winchester, MA",
      customerTitle: "Repair, Replace or Seal an Existing Skylight",
      date: "10/26/2016"
    },
    {
      stars: "N/A",
      heading: "Excellent Job",
      quote:
        "Excellent Job, professional, friendly, professional neat would refer to anyone.",
      customerName: "Evelyn L.",
      customerTitle: "Revere, MA - Remodel a Bathroom",
      date: "10/26/2016"
    }
  ]

}) => {
  const [sliderRef, setSliderRef] = useState(null);


  const formatWithLink = (str, length) => {
    const linkTo = 'https://www.homeadvisor.com/rated.FerragamoCustom.29690337.html';
    const linkText = 'Read More';
    const linkEl = <PrimaryLink href={linkTo} target="_blank" rel="noopener noreferrer">{linkText}</PrimaryLink>

    if (str.length <= length) {
      return <>{str}&nbsp;{linkEl}</>
    }

    const truncatedStr = str.slice(0, length) + '...';

    return (
      <>
        {truncatedStr}{linkEl}
      </>
    );
  };



  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <TextColumn textOnLeft={textOnLeft}>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <TestimonialSlider arrows={false} ref={setSliderRef}>
              {testimonials.map((testimonial, index) => (
                <Testimonial key={index}>
                  <StarsContainer>
                    {Array.from({ length: testimonial.stars }).map((_, indexIcon) => (
                      <StarIcon key={indexIcon} />
                    ))}
                  </StarsContainer>
                  <TestimonialHeading>{testimonial.heading}</TestimonialHeading>
                  <Quote>
                    {formatWithLink(testimonial.quote, 200)}
                  </Quote>
                  <CustomerInfoAndControlsContainer>
                    <CustomerInfo>
                      <CustomerTextInfo>
                        <CustomerName>{testimonial.customerName}</CustomerName>
                        <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                      </CustomerTextInfo>
                    </CustomerInfo>
                    <Controls>
                      <ControlButton onClick={sliderRef?.slickPrev}>
                        <ArrowLeftIcon />
                      </ControlButton>
                      <div className="divider" />
                      <ControlButton onClick={sliderRef?.slickNext}>
                        <ArrowRightIcon />
                      </ControlButton>
                    </Controls>
                  </CustomerInfoAndControlsContainer>
                </Testimonial>
              ))}
            </TestimonialSlider>
          </TextColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
